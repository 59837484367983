.nav-bar {
    background: #181818;
    width: 90px;
    height: 100%;
    position: absolute;
    top: 0;
    z-index: 3;
    min-height: 500px;

    .logo {
        display: block;
        padding: 8px 0;
    
        img {
            opacity: 1;
            display: block;
            margin: 8px auto;
            width: 24px;
            height: auto;
    
            &.sub-logo {
                width: 50px;
            }
        }
    }

    nav {
        display: block;
        text-align: center;
        position: absolute;
        height: 210px;
        top: 50%;
        margin-top: -120px;
        width: 100%;

        a {
            font-size: 30px;
            color: #4d4d4e;
            display: block;
            line-height: 51px;
            height: 51px;
            position: relative;
            text-decoration: none;
        

            i {
                transition: all 0.3s ease-out;
            }

            &:hover {
                color: #00c6ff;

                svg {
                    opacity: 0;
                }

                &:after {
                    opacity: 1;
                }
            }

            &:after {
                content: '';
                font-size: 9px;
                letter-spacing: 1px;
                position: absolute;
                bottom: 0;
                display: block;
                width: 100%;
                text-align: center;
                opacity: 0;
                transition: all 0.3s ease-out;
            }

            &:first-child {
                &::after {
                    content: 'HOME';
                    font-size: 13px;
                    font-weight: bold;
                }
            }
        }

        a.about-link {
            &:after {
                content: 'ABOUT';
                font-size: 13px;
                font-weight: bold
            }
        }

        a.contact-link {
            &:after {
                content: 'CONTACT';
                font-size: 13px;
                font-weight: bold;
            }
        }

        a.portfolio-link {
            &:after {
                content: 'PORTFOLIO';
                font-size: 11px;
                font-weight: bold;
                letter-spacing: 1px;
            }
        }

        a.linkedin-link {
            &:after {
                content: 'LINKEDIN';
                font-size: 11px;
                font-weight: bold;
            }
        }

        a.blog-link {
            &:after {
                content: 'BLOG';
                font-size: 12px;
                font-weight: bold;
            }
        }

        a.active {
            svg {
                color: #00c6ff;
            }
        }
    }
}

ul {
    position: absolute;
    bottom: 20px;
    width: 100%;
    display: block;
    padding: 0;
    list-style: none;
    text-align: center;
    margin: 0;

    li {
        a {
            padding: 7px 0;
            display: block;
            font-size: 35px;
            line-height: 16px;
            color: #4d4d4e;

            &:hover {
                .anchor-icon {
                    color: #00c6ff;
                }
            }
        }
    }
}

.hamburger-icon,
.close-icon {
    display: none;
}


@media screen and (max-width: 1200px) {
    .nav-bar {
        background: transparent;
        position: initial;
        height: auto;
        min-height: auto;

        ul, nav {
            display: none;
        }

        nav {
            width: 100%;
            height: 100%;
            background: #181818;
            top: 0;
            left: 0;
            position: fixed;
            z-index: 2;
            margin: 0;
            opacity: 0;
            animation: fadeIn 0.5s forwards;

            a {
                display: flex;
                justify-content: left;
                margin-left: 40%;
                align-items: center;
                font-weight: 500;
                font-size: 30px;
                color:#656566
            }
    
            a:after {
                opacity: 1;
                position: initial;
                width: auto;
                margin-left: 10px;
            }

            a svg {
                opacity: 1 !important;
            }

            &.mobile-show {
                display: block;
            }

        }


        .hamburger-icon,
        .close-icon {
            display: block;
            position: absolute;
            top: 15px;
            right: 15px;
        }

    }
}