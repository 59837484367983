.logo-container {
    z-index: 0;
    width: 400px;
    height: 609px;
    position: absolute;
    top: 0;
    right: 15%;
    bottom: 0;
    left: auto;
    margin: auto;
  
    .outline-logo {

      width: 100%;
      height: auto;
      bottom: 0;
      opacity: 0;
      transform: rotateZ(30deg);
      animation: rotateIn 1.5s forwards;
      animation-delay: 3.5s;

    }
  
    .solid-logo {
      position: absolute;
      top: auto;
      right: auto;
      bottom: auto;
      left: 0;
      margin: auto;
      width: 100%;
      opacity: 0;
      //transform: rotateZ(30deg);
      z-index: 1;
      animation: fadeIn 1.5s forwards;
      animation-delay: 5s;
    }

  }
  
  .svg-container {
    stroke: #00c6ff;
    stroke-width: 40;
  }
