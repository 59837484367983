.home-page {

    .text-zone {
        position: absolute;
        left: 10%;
        top: 50%;
        transform: translateY(-50%);
        width: 40%;
        max-height: 90%;
    }

    h1 {
        color: #fff;
        font-size: 56px;
        line-height: 53px;
        margin: 0;
        margin-bottom: 24px;
        font-family: 'Arial';
        font-weight: bold;
    }

    h2 {
        color: #8d8d8d;
        margin-top: 2px;
        font-weight: bold;
        font-size: 18px;
        font-family: 'Arial';
        letter-spacing: 2px;
        animation: fadeIn 1s 1.8s backwards;
    }

    .flat-button {
        color: #00c6ff;
        font-size: 13px;
        font-weight: bold;
        letter-spacing: 4px;
        font-family: 'Arial';
        text-decoration: none;
        padding: 10px 18px;
        margin-right: 24px;
        border: 2px solid #00c6ff;
        margin-top: 25px;
        float: left;
        animation: fadeInAnimation 1s 1.8s backwards;
        white-space: nowrap;

        &:hover {
            background: #00c6ff;
            color: #333;
        }

    }

}

@media screen and (max-width: 1200px) {

    h2 {
        font-size: 13.5px !important;
    }


    .tags,
    .home-page h1::before,
    .home-page h1::after {
        display: none;
    }

    .home-page {
        .text-zone {
            position: initial;
            width: 100%;
            transform: none;
            padding: 10px;
            box-sizing: border-box;
        }

        .flat-button {
            float: none;
            display: block;
            margin: 20px auto 0 auto;
            width: 125px;
        }

        .logo-container {
            position: relative;
            width: 225px;
            height: auto;
            top: 25px;
            right: 0;
            box-sizing: border-box;
            margin: auto;
            left: 0;

            svg {
                position: absolute;
                top: auto;
                right: auto;
                bottom: auto;
                left: 0;
                margin: auto;
            }
        }
    }
}