.blog-page {
    padding-left: 100px;
    padding-right: 50px;
    width: calc(100% - 150px);
    position: initial;
    height: 100%;
    overflow: auto;

    h1.page-title {
        margin-left: 100px;
        margin-top: 100px;
    }

    .images-container {
        display: flex;
        gap: 15px;
        flex-wrap: wrap;
        padding-bottom: 100px;
    }

    .image-box {
        position: relative;
        flex: 1 1 20%;
        height: 400px;
        overflow: hidden;
        border-radius: 10px;
        min-width: calc(50% - 10px);
        max-width: 100%;

        .blog-image {
            position: absolute;
            z-index: 2;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        .content {
            position: absolute;
            width: 100%;
            z-index: 3;
            padding: 10px 20px;
            transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
            background: linear-gradient(180deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
            bottom: 0px;
        }

        .title {
            margin-bottom: 0;
            margin-top: 0;
            color: #fff;
            font-size: 24px;
            font-weight: 600;
            line-height: 24px;
        }

        .date {
            font-size: 14px;
            margin-bottom: 5px;
            color: #fff;
            font-weight: 700;
        }

    }

}

@media screen and (max-width: 1200px) {
    .container.blog-page {
        width: 100%;
        padding: 20px;
        box-sizing: border-box;
        
        h1.page-title {
            margin-left: 20px;
            margin-top: 20px;
        }
    }

    .images-container {
        .image-box {
            height: 300px;
            max-width: 100%;
            min-width: 80%;
        }
    }
}