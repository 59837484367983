.page {
    width: 100%;
    height: 100%;
    position: absolute;
}

.container {
    width: 100%;
    will-change: contents;
    height: 90%;
    min-height: 566px;
    position: absolute;
    opacity: 0;
    top: 5%;
    margin: 0 auto;
    z-index: 1;
    transform-style: preserve-3d;
    animation: fadeIn 1s forwards;
    animation-delay: 1s;
}

.container.about-page,
.container.portfolio-page,
.container.blog-page,
.container.contact-page {
    h1 {
        
        font-size: 50px;
        font-family: 'Arial';
        color: #00c6ff;
        font-weight: bold;
        margin-top: 0;
        position: relative;
        margin-bottom: 10px;
        left: -10px;
    }

    h2 {
      margin: 0px;
      padding: 0px;
      animation: pulse 1s;
        &:nth-of-type(1) {
          animation-delay: 1.1s;
        }
        &:nth-of-type(2) {
          animation-delay: 1.2s;
        }
        &:nth-of-type(3) {
          animation-delay: 1.3s;
        }
        &:nth-of-type(4) {
            animation-delay: 1.4s;
        }
        &:nth-of-type(5) {
          animation-delay: 1.5s;
      }
    }
  
    p {
        font-size: 13px;
        color: #fff;
        font-family: 'Arial';
        font-weight: bold;
        max-width: fit-content;
        animation: pulse 1s;
        &:nth-of-type(1) {
          animation-delay: 1.1s;
        }
        &:nth-of-type(2) {
          animation-delay: 1.2s;
        }
        &:nth-of-type(3) {
          animation-delay: 1.3s;
        }
        &:nth-of-type(4) {
            animation-delay: 1.4s;
        }
        &:nth-of-type(5) {
          animation-delay: 1.5s;
        }
        &:nth-of-type(6) {
          animation-delay: 1.6s;
        }
        &:nth-of-type(7) {
        animation-delay: 1.7s;
     }
      
    }
  
    .text-zone {
      
      position: absolute;
      left: 10%;
      top: 45%;
      transform: translateY(-50%);
      width: 35%;
      vertical-align: middle;
      display: table-cell;
      max-height: 90%;
    }
  
    .text-animate-hover {
      &:hover {
        color: #fff;
      }
    }
  }

  @media screen and (max-width: 1200px) {


    .page {
        position: initial;
    }

    .container {
        position: initial;
        height: auto;
        min-height: auto;
        
        &.about-page,
        &.contact-page,
        &.blog-page
        &.portfolio-page {

            .text-zone {
                position: initial;
                transform: none;
                width: 100%;
                display: block;
                padding: 20px;
                box-sizing: border-box;
            }
        }
    }

  }